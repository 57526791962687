import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"

const Footer = props => {
  return (
    <footer className={styles.footer}>
      <div className={styles.column}>
        <h5><Link to="/" onClick={props.onClick}>home</Link></h5>
        <h5><a href="https://www.instagram.com/adviceworthgiving" target="_blank"
               rel="noopener noreferrer">instagram</a></h5>
        <h5><a href="https://www.facebook.com/adviceworthgiving" target="_blank" rel="noopener noreferrer">facebook</a>
        </h5>
      </div>
      <div className={styles.column}>
        <h5><a href="https://www.amazon.com/dp/B08B47MS5W" target="_blank" rel="noopener noreferrer">full ebook on Amazon</a></h5>
        <h5><a href="https://gumroad.com/renegens#TWxNO" target="_blank" rel="noopener noreferrer">buy on Gumroad</a></h5>
      </div>
      <div className={styles.column}>
        <h5><Link to="/random" onClick={props.onClick}>random advice</Link></h5>
      </div>
      <div className={styles.column}>
        <h5>© {new Date().getFullYear()}</h5>
        <h5>Rene Gens</h5>
      </div>
    </footer>
  )
}

export default Footer
